.ResertPassword {
    background-image: url("../../assets/BackgroudImage/residence.jpg");
    background-size: cover;
    background-position: 0% 90%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100%;
    height: 700px;
    filter: grayscale(70%); 
    /* filter: grayscale(80%); */
}
.ResertPasswordBackgroundContent {
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
}
.ResertPasswordHeader{
    color: black;
    font-size: 0.7rem;
}
.ResertPasswordHeader h1{
    background-color: #a7cddd;
    border-radius: 2px;
    box-shadow: 7px 10px 5px 0px rgba(0,0,0,0.75);
    font-family: "Montserrat", sans-serif;
}
.ResertPasswordUnderline{
    width: 100%;
    height: 1px;
    background-color: black;
}
.ResertPasswordFormCotainer {
    width: 100%;
}

.ResertPasswordForm {
    display: flex;
    flex-direction: column;
}
.ResertPasswordForm label{
    font-size: 1.5rem;
    font-weight: 500;
    color: black;
}
.ResertPasswordForm label{
    background-color: #a7cddd;
    border-radius: 2px;
    margin-top: 15px;
}
.ResertPasswordForm input{
    height: 30px;
    border-radius: 5px;
    border: 2px solid black;
    font-size: 1.2rem;
    margin-top: 3px;
}


.ResertPasswordForm button {
    margin-top: 5px;
    color: black;
    width: 90px;
    height: 40px;
    font-size: 1.2rem;
    font-weight: 100;
    cursor: pointer;
    background-color: white;
    border-radius: 5px;
    border: 2px solid black;
}
.ResertPasswordForm button:active,
.ResertPasswordForm button:hover {
    color: white;
    background-color: #b0b5bb;
}

@media(min-width: 500px){
    .ResertPasswordUnderline{
        width: 60%;
        height: 1px;
        background-color: black;
    }
    
    .ResertPasswordFormCotainer {
        margin-top: 10px;
        width: 60%;
    }
}


@media(min-width: 750px){
    .ResertPasswordHeader{
        font-size: 0.8rem;
    }
    .ResertPasswordUnderline{
        width: 50%;
    }
    
    .ResertPasswordFormCotainer {
        width: 50%;
    }
    
    .ResertPasswordForm label{
        font-size: 1.5rem;
        font-weight: 500;
        color: black;
    }
    .ResertPasswordForm input,
    .ResertPasswordForm select{
        height: 30px;
        border-radius: 5px;
    }
    .ResertPasswordForm select{
        height: 36px;
    }
    
}
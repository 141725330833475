.UploadProperty {
    background-image: url("../../assets/BackgroudImage/residence.jpg");
    background-size: cover;
    background-position: 0% 90%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100%;
    height: 1000px;
    filter: grayscale(70%); 
    /* filter: grayscale(80%); */
}

.UploadPropertyBackgroundContent {
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 100px;
}
.UploadPropertyHeader{
    color: black;
    font-size: 0.7rem;
}
.UploadPropertyHeader h1{
    background-color: #a7cddd;
    border-radius: 2px;
    box-shadow: 7px 10px 5px 0px rgba(0,0,0,0.75);
    font-family: "Montserrat", sans-serif;
}
.UploadPropertyUnderline{
    width: 100%;
    height: 1px;
    background-color: black;
}

.UploadPropertyFormCotainer {
    width: 100%;
}

.UploadPropertyForm {
    display: flex;
    flex-direction: column;
}
.UploadPropertyForm label{
    font-size: 1.5rem;
    font-weight: 500;
    color: black;
}
.UploadPropertyForm label{
    background-color: #a7cddd;
    border-radius: 2px;
    margin-top: 15px;
}
.UploadPropertyForm input,
.UploadPropertyForm select{
    height: 30px;
    border-radius: 5px;
    border: 2px solid black;
    font-size: 1.2rem;
    margin-top: 3px;
}
.UploadPropertyForm select{
    height: 36px;
}
.UploadPropertyForm input[type="file"]{
    color: white;
    border: transparent;
}
.UploadPropertyForm button {
    margin-top: 5px;
    color: black;
    width: 90px;
    height: 40px;
    font-size: 1.2rem;
    font-weight: 100;
    cursor: pointer;
    background-color: white;
    border-radius: 5px;
    border: 2px solid black;
}
.UploadPropertyForm button:active,
.UploadPropertyForm button:hover {
    color: white;
    background-color: #b0b5bb;
}

@media(min-width: 500px){
    .UploadPropertyUnderline{
        width: 60%;
        height: 1px;
        background-color: black;
    }
    
    .UploadPropertyFormCotainer {
        margin-top: 10px;
        width: 60%;
    }
}

@media(min-width: 750px){
    .UploadPropertyHeader{
        font-size: 0.8rem;
    }
    .UploadPropertyUnderline{
        width: 50%;
    }
    
    .UploadPropertyFormCotainer {
        width: 50%;
    }
    
    .UploadPropertyForm label{
        font-size: 1.5rem;
        font-weight: 500;
        color: black;
    }
    .UploadPropertyForm input,
    .UploadPropertyForm select{
        height: 30px;
        border-radius: 5px;
    }
    .UploadPropertyForm select{
        height: 36px;
    }
    
}
.ToggleButton {
    width: 35px;
    height: 35px;
    background-color: white;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
}
.ToggleButton div{
    width: 80%;
    height: 3px;
    background-color: black;
    border-radius: 2px;
    margin: 3px;
}
.ResertPasswordAuthenticate {
    background-image: url("../../assets/BackgroudImage/residence.jpg");
    background-size: cover;
    background-position: 0% 90%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100%;
    height: 700px;
    filter: grayscale(70%); 
    /* filter: grayscale(80%); */
}
.ResertPasswordAuthenticateBackgroundContent {
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
}
.ResertPasswordAuthenticateHeader{
    color: black;
    font-size: 0.7rem;
}
.ResertPasswordAuthenticateHeader h1{
    background-color: #a7cddd;
    border-radius: 2px;
    box-shadow: 7px 10px 5px 0px rgba(0,0,0,0.75);
    font-family: "Montserrat", sans-serif;
}
.ResertPasswordAuthenticateUnderline{
    width: 100%;
    height: 1px;
    background-color: black;
}
.ResertPasswordAuthenticateFormCotainer {
    width: 100%;
}

.ResertPasswordAuthenticateForm {
    display: flex;
    flex-direction: column;
}
.ResertPasswordAuthenticateForm label{
    font-size: 1.5rem;
    font-weight: 500;
    color: black;
}
.ResertPasswordAuthenticateForm label{
    background-color: #a7cddd;
    border-radius: 2px;
    margin-top: 15px;
}
.ResertPasswordAuthenticateForm input{
    height: 30px;
    border-radius: 5px;
    border: 2px solid black;
    font-size: 1.2rem;
    margin-top: 3px;
}


.ResertPasswordAuthenticateForm button {
    margin-top: 5px;
    color: black;
    width: 90px;
    height: 40px;
    font-size: 1.2rem;
    font-weight: 100;
    cursor: pointer;
    background-color: white;
    border-radius: 5px;
    border: 2px solid black;
}
.ResertPasswordAuthenticateForm button:active,
.ResertPasswordAuthenticateForm button:hover {
    color: white;
    background-color: #b0b5bb;
}

@media(min-width: 500px){
    .ResertPasswordAuthenticateUnderline{
        width: 60%;
        height: 1px;
        background-color: black;
    }
    
    .ResertPasswordAuthenticateFormCotainer {
        margin-top: 10px;
        width: 60%;
    }
}


@media(min-width: 750px){
    .ResertPasswordAuthenticateHeader{
        font-size: 0.8rem;
    }
    .ResertPasswordAuthenticateUnderline{
        width: 50%;
    }
    
    .ResertPasswordAuthenticateFormCotainer {
        width: 50%;
    }
    
    .ResertPasswordAuthenticateForm label{
        font-size: 1.5rem;
        font-weight: 500;
        color: black;
    }
    .ResertPasswordAuthenticateForm input,
    .ResertPasswordAuthenticateForm select{
        height: 30px;
        border-radius: 5px;
    }
    .ResertPasswordAuthenticateForm select{
        height: 36px;
    }
    
}
.DeleteProperty {
    background-image: url("../../assets/BackgroudImage/residence.jpg");
    background-size: cover;
    background-position: 0% 90%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100%;
    height: 700px;
    filter: grayscale(70%); 
    /* filter: grayscale(80%); */
}
.DeletePropertyBackgroundContent {
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    padding-right: 5px;
}
.DeletePropertyHeader {
    display: flex;
    justify-content: space-between;
}
.ProvinceHeader {
    border-radius: 5px;
    margin: 25.920px 0;
}
.ProvinceHeader h2{
    margin: 0;
    padding: 3px;
    width: 125px;
}
.SectionHeader {
    border-radius: 5px;
    margin: 25.920px 0;
}
.SectionHeader h2{
    margin: 0;
    padding: 3px;
    width: 125px;
}
.DeletePropertyImageOuterContainer {
    width: 100%;
    height: 75%;
    overflow-y: auto;
    margin-top: 10px;
}
.DeletePropertyImageInnerContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill,17rem);
    /* grid-template-rows: 10rem; */
    grid-auto-rows: 23rem;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    justify-content: center;
    margin-top: 10px;
    overflow-y:auto;
}
.DeleteProperty_Spinner {
    position: fixed;
    left: 50%;
    top: 40%;
    width: 300px;
    height: 100px;
    z-index: 100;
    margin: auto;
    
}
.DeletePropertyNoImages {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
}
.DeletePropertyNoImages h3{
    background-color: #a7cddd;
    border-radius: 2px;
}
@media(min-width: 300px){
    .DeletePropertyImageInnerContainer {
        grid-template-columns: repeat(auto-fill,18rem);
        grid-auto-rows: 23rem;
        
    }
}
@media(min-width: 320px){
    .DeletePropertyImageInnerContainer {
        grid-template-columns: repeat(auto-fill,19rem);
        grid-auto-rows: 23rem;
        
    }
}
@media(min-width: 330px){
    .DeletePropertyImageInnerContainer {
        grid-template-columns: repeat(auto-fill,20rem);
        grid-auto-rows: 23rem;
        
    }
}
@media(min-width: 340px){
    .DeletePropertyImageInnerContainer {
        grid-template-columns: repeat(auto-fill,20.7rem);
        grid-auto-rows: 23rem;
        
    }
}
@media(min-width: 360px){
    .DeletePropertyImageInnerContainer {
        grid-template-columns: repeat(auto-fill,21.5rem);
        grid-auto-rows: 23rem;
        
    }
}
@media(min-width: 380px){
    .DeletePropertyImageInnerContainer {
        grid-template-columns: repeat(auto-fill,23rem);
        grid-auto-rows: 23rem;
        
    }
}
@media(min-width: 400px){
    .DeletePropertyImageInnerContainer {
        grid-template-columns: repeat(auto-fill,24rem);
        grid-auto-rows: 23rem;
        
    }
}
@media(min-width: 420px){
    .DeletePropertyImageInnerContainer {
        grid-template-columns: repeat(auto-fill,25rem);
        grid-auto-rows: 23rem;
        
    }
}
@media(min-width: 440px){
    .DeletePropertyImageInnerContainer {
        grid-template-columns: repeat(auto-fill,27rem);
        grid-auto-rows: 23rem;
        
    }
}
@media(min-width: 470px){
    .DeletePropertyImageInnerContainer {
        grid-template-columns: repeat(auto-fill,28rem);
        grid-auto-rows: 23rem;
        
    }
}
@media(min-width: 540px){
    .DeletePropertyImageInnerContainer {
        grid-template-columns: repeat(auto-fill,16rem);
        grid-auto-rows: 23rem;
        
    }
}
@media(min-width: 560px){
    .DeletePropertyImageInnerContainer {
        grid-template-columns: repeat(auto-fill,17rem);
        grid-auto-rows: 23rem;
        
    }
}
@media(min-width: 600px){
    .DeletePropertyImageInnerContainer {
        grid-template-columns: repeat(auto-fill,18rem);
        grid-auto-rows: 24rem;
        
    }
}
@media(min-width: 640px){
    .DeletePropertyImageInnerContainer {
        grid-template-columns: repeat(auto-fill,19rem);
        grid-auto-rows: 24rem;
        
    }
}
@media(min-width: 670px){
    .DeletePropertyImageInnerContainer {
        grid-template-columns: repeat(auto-fill,20rem);
        grid-auto-rows: 24rem;
        
    }
}
@media(min-width: 705px){
    .DeletePropertyImageInnerContainer {
        grid-template-columns: repeat(auto-fill,21rem);
        grid-auto-rows: 24rem;
        
    }
}

@media(min-width: 750px){
    
    .DeletePropertyBackgroundContent {
        padding-top: 110px;
    }
    .ProvinceHeader h2{
        margin: 0;
        padding: 3px;
        width: auto;
    }

    .SectionHeader h2{
        margin: 0;
        padding: 3px;
        width: auto;
    }
    .DeletePropertyImageInnerContainer {
        grid-template-columns: repeat(auto-fill,28rem);
        grid-auto-rows: 25rem;
        
    }

}
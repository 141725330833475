.SideDrawer {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  opacity: 0;
  visibility: hidden; /* Start with hidden visibility */
  pointer-events: none; /* Disable interaction when invisible */
  transition: opacity 0.5s ease, visibility 0s linear 0.5s; /* Visibility delay matches fade-out duration */
}

/* FadeIn animation */
.FadeIn {
  opacity: 1;
  visibility: visible; /* Element becomes visible */
  pointer-events: all; /* Enable interaction */
  transition: opacity 0.5s ease, visibility 0s;
}

/* FadeOut animation */
.FadeOut {
  opacity: 0;
  pointer-events: none; /* Disable interaction during fade-out */
  visibility: hidden; /* Hide element after fade-out */
  transition: opacity 0.5s ease, visibility 0s linear 0.5s; /* Visibility changes after opacity transition */
}

.SideDrawer div {
  width: 100%;
}

@media(min-width: 750px) {
  .SideDrawer {
      width: 50%;
      height: 100%;
      display: none; /* Hide SideDrawer on larger screens */
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
label {
  font-family: "Montserrat", sans-serif;
  box-shadow: 7px 10px 5px 0px rgba(0,0,0,0.75);
}
input,select,textarea {
  font-family: "Montserrat", sans-serif;
  box-shadow: 7px 10px 5px 0px rgba(0,0,0,0.75);
}
button {
  font-family: "Montserrat", sans-serif;
  font-weight: 500 !important;
  box-shadow: 7px 10px 5px 0px rgba(0,0,0,0.75);
}
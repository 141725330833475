.NavigationItems {
    list-style: none;
    display: flex;
    margin-right: 10px;
    padding: 0;
    margin: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    
}
@media(min-width: 750px){
    .NavigationItems {
        flex-direction: row;
    }
}
.AboutUs {
    background-image: url("../../assets/BackgroudImage/residence.jpg");
    background-size: cover;
    background-position: 0% 90%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100%;
    height: 700px;
    filter: grayscale(70%); 
    /* filter: grayscale(80%); */
}
.AboutUsBackgroundContent {
    padding-top: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
}
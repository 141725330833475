.AvailablePropertyImageCard {
    display: inline-block;
    border: 2px solid transparent;
    overflow: hidden;
    border-radius: 3px;
    background-color: #41464b;
}
.AvailablePropertyImageCardFunctions {
    display: flex;
    justify-content: space-between;
    height: 5%;
}
.AvailablePropertyImageCardFunctions div button {
    background-color: #0097e6;
    color: white;
    border: 2px solid #0097e6;
    cursor: pointer;
}
.AvailablePropertyImageCardFunctions div button:active,
.AvailablePropertyImageCardFunctions div button:hover {
    background-color: black;
    color: white;
    border: 2px solid black;
    cursor: pointer;
}
.paragraphState {
    background-color: #0097e6;
    color: white;
    border: 2px solid #0097e6;
    padding: 0;
    margin: 0;
}
.AvailablePropertyImageCardInfo{
    width: 100%;
    height: 17.8%;
    background-color: #41464b;
    color: white;
}
.AvailablePropertyImageCardDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.AvailablePropertyImageCardAddress{
    margin-top: 2px;
}
.AvailablePropertyImageCardInfo h5{
    margin: 0;
    padding-left: 3px;
}
/* .whitenAddress1{
    color: rgb(221, 216, 216);
} */
.whitenAddress2{
    color: rgb(136, 133, 133);
}
.whitenAddress3{
    color: rgb(112, 109, 109);
}
.whitenAddress4{
    color: rgb(82, 80, 80);
}
.AvailablePropertyImageCardImage {
    width: 100%;
    height: 78%;
    overflow: hidden;
}
.AvailablePropertyImageCardImage img{
    width: 100%;
    height: 100%;
}
.AvailablePropertyImageCardImage img:active,
.AvailablePropertyImageCardImage img:hover{
    transition: transform .5s ease;
    transform: scale(1.5);
    cursor: zoom-in;
}

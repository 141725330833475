.ListOfCardsInfo{
    font-weight: 500;
    background-color: white;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid black;
    margin-bottom: 0.5rem;
    box-shadow: 7px 10px 5px 0px rgba(0,0,0,0.75);

}
.ListOfCardsInfo p{
    text-align: center
}
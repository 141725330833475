.ImageSlider {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    background-color: #808285;
}
.ImageSliderCancelButton {
    align-self: flex-end;
    margin-top: 1rem;
    margin-right: 1rem;
    margin-bottom: 0.1rem;
}
.ImageSliderCancelButton button{
    width: 40px;
    background-color: white;
    border-radius: 5px;
    border: 2px solid white;
    cursor: pointer;
}
.ImageSliderCancelButton button:active,
.ImageSliderCancelButton button:hover{
    background-color: #0097e6;
    border: 2px solid #0097e6;
}
.ImageSliderDescription {
    /* position: absolute;
    top: 7%; */
    margin-bottom: 1px;
 }
.ImageSliderDescription h5{
   margin: 0;
   color: white;
   
}
.ImageSliderInfo{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.ImageSliderInfoColor {
    color: #2C3A47;
}
.ImageSliderInfoAdress {
    margin-left: 5px;
}
.ImageSliderInfoFirstPart {
    margin-left: 5px;
}
.ImageSliderInfoSecondPart {
    margin-right: 5px;
}
.ImageSliderMiddle {
    /* position: absolute;
    top: 10%; */
    height: 100%;
}
.ImageSliderContainer {
    width: 100%;
    height: 100%;
}
.ImageSliderContainer img {
   width: 100%;
   height: 100%;
}



.rightArrow {
    position:absolute;
    top: 50%;
    right: 1%;
    bottom: 50%;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    background-color: white;
    border-radius: 50%;
}
.leftArrow {
    position:absolute;
    top: 50%;
    left: 1%;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    background-color: white;
    border-radius: 50%;
}

.Popup {
    position: fixed;
    left: 40%;
    top: 40%;
    width: 300px;
    height: 100px;
    background-color: white;
    z-index: 100;
    margin: auto;
    border-radius: 5px;
    box-shadow: 1px 1px 2px white, 7px 0 7px black, 0 0 70px black;
}
.PopupWithTwoButtons {
    position: fixed;
    left: 40%;
    top: 40%;
    width: 300px;
    height: 100px;
    background-color: white;
    z-index: 100;
    margin: auto;
    border-radius: 5px;
    box-shadow: 1px 1px 2px white, 7px 0 7px black, 0 0 70px black;
}
.Popup h2{
    color: black;
}
.PopupWithTwoButtons h2{
    color: black;
    margin-bottom: 1.8rem;
}
.Popup button{
    position: absolute;
    left: 35%;
    bottom: 10px;
    width: 100px;
    height: 30px;
    background-color: black;
    border-radius: 7px;
    color: white;
    cursor: pointer;
}
.buttonDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:flex-end;
}
.twoButtonOne {
    margin-left: 1rem;
}
.twoButtonTwo{
    margin-right: 1rem;
}
.PopupWithTwoButtons button{
    width: 100px;
    height: 30px;
    background-color: black;
    border-radius: 7px;
    color: white;
    cursor: pointer;
}

.Popup button:active,
.Popup button:hover{
    background-color: #1B1464;
}
.PopupWithTwoButtons button:active,
.PopupWithTwoButtons button:hover{
    background-color: #1B1464;
}
@media(max-width: 280px){
    .Popup {
        width: 250px;
        height: 80px;
    }
    .PopupWithTwoButtons {
        width: 250px;
        height: 80px;        
    }
    .PopupWithTwoButtons h2{   
        margin-bottom: 1rem;
    }
}
@media(min-width: 200px){
    .Popup {
        left: 2%;
    }
    .Popup h2{
        color: black;
        font-size: 15px;
        text-align: center;
    }
    .PopupWithTwoButtons {
        left: 2%;
    }
    .PopupWithTwoButtons h2{
        color: black;
        font-size: 15px;
        text-align: center;
    }
}
@media(min-width: 280px){
    .Popup {
        left: 3%;
    }
    .PopupWithTwoButtons {
        left: 3%;
    }
}
@media(min-width: 320px){
    .Popup {
        left: 4%;
    }
    .PopupWithTwoButtons {
        left: 4%;
    }
}
@media(min-width: 330px){
    .Popup {
        left: 5%;
    }
    .PopupWithTwoButtons {
        left: 5%;
    }
}
@media(min-width: 350px){
    .Popup {
        left: 7%;
    }
    .PopupWithTwoButtons {
        left: 7%;
    }
}
@media(min-width: 375px){
    .Popup {
        left: 8%;
    }
    .PopupWithTwoButtons {
        left: 8%;
    }
}
@media(min-width: 400px){
    .Popup {
        left: 13%;
    }
    .PopupWithTwoButtons {
        left: 13%;
    }
}
@media(min-width: 450px){
    .Popup {
        left: 18%;
    }
    .PopupWithTwoButtons {
        left: 18%;
    }
}
@media(min-width: 500px){
    .Popup {
        left: 20%;
    }
    .PopupWithTwoButtons {
        left: 20%;
    }
}
@media(min-width: 550px){
    .Popup {
        left: 26%;
    }
    .PopupWithTwoButtons {
        left: 26%;
    }
}
@media(min-width: 600px){
    .Popup {
        left: 29%;
    }
    .PopupWithTwoButtons {
        left: 29%;
    }
}
@media(min-width: 700px){
    .Popup {
        left: 27%;
        width: 400px;
        height: 120px;
    }
    .Popup h2{
        color: black;
        font-size: 25px;
        text-align: center;
    }
    .Popup button{
        left: 35%;
        width: 130px;
        height: 40px;
        font-size: 20px;
    }
    .PopupWithTwoButtons {
        left: 27%;
        width: 400px;
        height: 120px;
    }
    .PopupWithTwoButtons h2{
        color: black;
        font-size: 25px;
        text-align: center;
    }
    .PopupWithTwoButtons button{
        width: 130px;
        height: 40px;
        font-size: 20px;
    }
}

@media(min-width: 830px){
    .Popup {
        left: 30%;
    }
    .PopupWithTwoButtons {
        left: 30%;
    }
}
@media(min-width: 1000px){
    .Popup {
        left: 28%;
        width: 500px;
        height: 150px;
    }
    .Popup h2{
        color: black;
        font-size: 27px;
        text-align: center;
    }
    .Popup button{
        left: 36%;
        width: 130px;
        height: 40px;
        font-size: 20px;
    }
    .PopupWithTwoButtons {
        left: 28%;
        width: 500px;
        height: 150px;
    }
    .PopupWithTwoButtons h2{
        color: black;
        font-size: 27px;
        text-align: center;
    }
    .PopupWithTwoButtons button{
        width: 130px;
        height: 40px;
        font-size: 20px;
    }    
}




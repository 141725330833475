.Backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(8, 8, 8, 0.671);
    z-index: 1;
    opacity: 0;
    pointer-events: none; /* Disable interaction by default */
    transition: opacity 0.5s ease; /* Smooth transition for fade-in and fade-out */
}

/* FadeIn animation */
.FadeIn {
    opacity: 1;
    pointer-events: all; /* Enable interaction when fully visible */
}

/* FadeOut animation */
.FadeOut {
    opacity: 0;
    pointer-events: none; /* Disable interaction during fade-out */
}
